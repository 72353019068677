<template>
  <div class="home">
    <input id="" type="file" name="" @change="loadImg">
    <image-cropper v-if="uploadDialog" :image="img" @close="uploadDialog = false" />
  </div>
</template>

<script>
// @ is an alias to /src
import ImageCropper from '@/components/ImageCropper.vue'
import { imgLoad } from '@/utils/helper'

export default {
  name: 'Home',
  components: { ImageCropper },
  data: () => ({
    uploadDialog: false,
    img: {
      file: null,
      name: null,
    },
  }),
  methods: {
    loadImg (e) {
      const image = imgLoad(e)
      this.img = image
      this.uploadDialog = true
    },
  },
}
</script>
